import { Reactor } from '../reactor/Reactor'
import { useCallback, useEffect, useState } from 'react'
import { ReactorItem, NuclearReactor, NuclearTile, NuclearReactorSize } from '../reactor/types'
import { createReactor, forEachReactorPos, getItemIndex, setItemAtPos } from '../reactor/utils'
import clone from 'just-clone'
import simulate from '../reactor/simulate'
import ReactorStats from '../reactor/ReactorStats'
import ReactorItemSelector from '../reactor/ReactorItemSelector/ReactorItemSelector'
import { useSearchParams } from 'react-router-dom'
import { deserializeReactor } from '../reactor/serialization'
import ShareButton from '../ShareButton'
import { useSimulationHistory } from '../hooks/useSimulationHistory'

import style from './Home.module.scss'

export default function Home() {
    const [selectedItem, setSelectedItem] = useState<ReactorItem | undefined>()
    const [reactor, setReactor] = useState<NuclearReactor>(createReactor(9))
    const [simulationSpeed, setSimulationSpeed] = useState<number | null>(50)
    const [simulationHistory, appendHistory, resetSimulationHistory] = useSimulationHistory(reactor.size)

    const onClickTile = (tile: NuclearTile, shiftKeyPressed: boolean) => {
        if (!selectedItem) {
            return
        }
        const newReactor = clone(reactor)
        setItemAtPos(selectedItem, newReactor, tile.xPos, tile.yPos)

        if (shiftKeyPressed) {
            forEachReactorPos(reactor.size, (x, y) => {
                if (getItemIndex(reactor.tiles[y][x]) === getItemIndex(tile)) {
                    setItemAtPos(selectedItem, newReactor, x, y)
                }
            })
        }

        setReactor(newReactor)
        resetSimulationHistory()
    }

    const [searchParams] = useSearchParams()

    useEffect(() => {
        const loadReactor = searchParams.get('reactor')
        if (loadReactor) {
            const loadedReactor = deserializeReactor(loadReactor)
            if (loadedReactor) {
                setReactor(loadedReactor)
            }
        }
    }, [searchParams])

    useEffect(() => {
        if (simulationSpeed != null) {
            const interval = setInterval(
                () =>
                    setReactor((prevReactor) => {
                        if (prevReactor.currentHistoryStep === 0) {
                            appendHistory(prevReactor)
                        }
                        return simulate(prevReactor)
                    }),
                simulationSpeed
            )
            return () => clearInterval(interval)
        }
    }, [reactor, simulationSpeed])

    const setReactorSize = useCallback((newSize: NuclearReactorSize) => {
        setReactor(createReactor(newSize))
        resetSimulationHistory()
    }, [])

    return (
        <>
            <ReactorItemSelector selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            <Reactor reactor={reactor} onClickTile={onClickTile} />
            <ReactorStats reactor={reactor} />
            <h3>Reactor size:</h3>
            <div className={style.buttonRow}>
                <button onClick={() => setReactorSize(3)}>Small</button>
                <button onClick={() => setReactorSize(5)}>Medium</button>
                <button onClick={() => setReactorSize(7)}>Large</button>
                <button onClick={() => setReactorSize(9)}>Huge</button>
            </div>
            <h4>Simulation speed:</h4>
            <div className={style.buttonRow}>
                <button onClick={() => setSimulationSpeed(null)}>Paused</button>
                <button onClick={() => setSimulationSpeed(50)}>Normal</button>
                <button onClick={() => setSimulationSpeed(5)}>Fast</button>
            </div>
            <ShareButton reactor={reactor} />
            <div>{`History length: ${simulationHistory.tiles
                .map((tile) => tile.historyLength)
                .reduce((a, b) => (a > b ? a : b))}`}</div>
        </>
    )
}
