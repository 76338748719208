import { useEffect, useState } from 'react'
import { ReactorSimulationSummary } from '../reactor/types'

interface RequestStatePending {
    status: 'loading'
}

interface RequestStateError {
    status: 'error'
    error: any
}

interface RequestStateSuccess<TData> {
    status: 'success'
    data: TData
}

type RequestState<TData> = RequestStateError | RequestStatePending | RequestStateSuccess<TData>

interface ReactorListing {
    reactor: string
    summary: ReactorSimulationSummary
}

export default function useReactorTopList() {
    const [state, setState] = useState<RequestState<ReactorListing[]>>(() => ({ status: 'loading' }))

    useEffect(() => {
        setState({
            status: 'success',
            data: [
                {
                    reactor: 'AJRRRRRRRRRRRRRRUUUUURRRRUUYUURRRRUYCYURRRRUUYUURRRRUUUUURRRRRRRRRRRRRR',
                    summary: {
                        euGeneration: 72654,
                        neutronAbsorptions: 3767,
                        euFuelConsumption: 111487,
                        fluidReactionsWater: 1357.04,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 523.0,
                        deuteriumProduction: 1357.04,
                        tritiumProduction: 523.0,
                    },
                },
                {
                    reactor: 'AFRRRRUUURUNUNURUUURRRR',
                    summary: {
                        euGeneration: 60932,
                        neutronAbsorptions: 5219,
                        euFuelConsumption: 281193,
                        fluidReactionsWater: 586.98,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 349.33,
                        deuteriumProduction: 586.98,
                        tritiumProduction: 349.33,
                    },
                },
                {
                    reactor: 'AJRRRRRRTWTWTRRTTTTTTTRRTTYTYTTRRWYETEYWRRTTYTYTTRRTTTTTTTRRTWTWTRRRRRR',
                    summary: {
                        euGeneration: 99440,
                        neutronAbsorptions: 11129,
                        euFuelConsumption: 231858,
                        fluidReactionsWater: 207.69,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 2180.74,
                        fluidReactionsHighPressureHeavyWater: 0.0,
                        deuteriumProduction: 2388.43,
                        tritiumProduction: 0.0,
                    },
                },
                {
                    reactor: 'AJWWWWWWRRRRRWWRRTUTRRWWRTTYTTRWWRUYKYURWWRTTYTTRWWRRTUTRRWWRRRRRWWWWWW',
                    summary: {
                        euGeneration: 85823,
                        neutronAbsorptions: 8410,
                        euFuelConsumption: 173888,
                        fluidReactionsWater: 745.8,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 861.83,
                        fluidReactionsHighPressureHeavyWater: 126.37,
                        deuteriumProduction: 1607.63,
                        tritiumProduction: 126.37,
                    },
                },
                {
                    reactor: 'AJRURURRUNUNURRUNUUUNURUNUUUUUNURUUUNUUURUNUUUUUNURUNUUUNURRUNUNURRURUR',
                    summary: {
                        euGeneration: 269324,
                        neutronAbsorptions: 28918,
                        euFuelConsumption: 1681896,
                        fluidReactionsWater: 1359.76,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 1880.74,
                        deuteriumProduction: 1359.76,
                        tritiumProduction: 1880.74,
                    },
                },
                {
                    reactor: 'AJWWWWWWRRRRRWWRRTUTRRWWRTTYTTRWWRUYQYURWWRTTYTTRWWRRTUTRRWWRRRRRWWWWWW',
                    summary: {
                        euGeneration: 93833,
                        neutronAbsorptions: 9948,
                        euFuelConsumption: 255193,
                        fluidReactionsWater: 835.3,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 797.97,
                        fluidReactionsHighPressureHeavyWater: 156.24,
                        deuteriumProduction: 1633.27,
                        tritiumProduction: 156.24,
                    },
                },
                {
                    reactor: 'AJWWWWWWWRRRWWWWRRRRRWWWRRTUTRRWWRRUEURRWWRRTUTRRWWWRRRRRWWWWRRRWWWWWWW',
                    summary: {
                        euGeneration: 67392,
                        neutronAbsorptions: 6892,
                        euFuelConsumption: 125529,
                        fluidReactionsWater: 919.29,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 548.82,
                        fluidReactionsHighPressureHeavyWater: 131.72,
                        deuteriumProduction: 1468.11,
                        tritiumProduction: 131.72,
                    },
                },
                {
                    reactor: 'ADRRRRKRRRR',
                    summary: {
                        euGeneration: 9815,
                        neutronAbsorptions: 710,
                        euFuelConsumption: 41339,
                        fluidReactionsWater: 146.88,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 0.0,
                        deuteriumProduction: 146.88,
                        tritiumProduction: 0.0,
                    },
                },
                {
                    reactor: 'AJWWWWWWWRRRWWWWRRRRRWWWRRTUTRRWWRRUHURRWWRRTUTRRWWWRRRRRWWWWRRRWWWWWWW',
                    summary: {
                        euGeneration: 67215,
                        neutronAbsorptions: 6745,
                        euFuelConsumption: 129686,
                        fluidReactionsWater: 808.79,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 502.64,
                        fluidReactionsHighPressureHeavyWater: 105.02,
                        deuteriumProduction: 1311.43,
                        tritiumProduction: 105.02,
                    },
                },
                {
                    reactor: 'ADRRRRERRRR',
                    summary: {
                        euGeneration: 10133,
                        neutronAbsorptions: 792,
                        euFuelConsumption: 39454,
                        fluidReactionsWater: 170.92,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 0.0,
                        deuteriumProduction: 170.92,
                        tritiumProduction: 0.0,
                    },
                },
                {
                    reactor: 'AJEREREARARARAERERERERERARARARARERERERERERARARARAREREREREREARARARAERERE',
                    summary: {
                        euGeneration: 112754,
                        neutronAbsorptions: 16860,
                        euFuelConsumption: 981667,
                        fluidReactionsWater: 1201.84,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 0.0,
                        fluidReactionsHighPressureHeavyWater: 0.0,
                        deuteriumProduction: 1201.84,
                        tritiumProduction: 0.0,
                    },
                },
                {
                    reactor: 'AJWWWWWWWRRRWWWWRRRRRWWWRRTUTRRWWRRUNURRWWRRTUTRRWWWRRRRRWWWWRRRWWWWWWW',
                    summary: {
                        euGeneration: 70749,
                        neutronAbsorptions: 7300,
                        euFuelConsumption: 151897,
                        fluidReactionsWater: 892.18,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 588.64,
                        fluidReactionsHighPressureHeavyWater: 127.32,
                        deuteriumProduction: 1480.82,
                        tritiumProduction: 127.32,
                    },
                },
                {
                    reactor: 'AJWWWWWWRRRRRWWRRTUTRRWWRTTYTTRWWRUYEYURWWRTTYTTRWWRRTUTRRWWRRRRRWWWWWW',
                    summary: {
                        euGeneration: 89152,
                        neutronAbsorptions: 9260,
                        euFuelConsumption: 165941,
                        fluidReactionsWater: 833.31,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 990.78,
                        fluidReactionsHighPressureHeavyWater: 121.12,
                        deuteriumProduction: 1824.09,
                        tritiumProduction: 121.12,
                    },
                },
                {
                    reactor: 'AJWWWWWWWRRRWWWWRRRRRWWWRRTUTRRWWRRUKURRWWRRTUTRRWWWRRRRRWWWWRRRWWWWWWW',
                    summary: {
                        euGeneration: 65417,
                        neutronAbsorptions: 6321,
                        euFuelConsumption: 133482,
                        fluidReactionsWater: 784.7,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 459.95,
                        fluidReactionsHighPressureHeavyWater: 105.37,
                        deuteriumProduction: 1244.65,
                        tritiumProduction: 105.37,
                    },
                },
                {
                    reactor: 'AJWWWWWWWRRRWWWWRRRRRWWWRRTUTRRWWRRUQURRWWRRTUTRRWWWRRRRRWWWWRRRWWWWWWW',
                    summary: {
                        euGeneration: 71221,
                        neutronAbsorptions: 7434,
                        euFuelConsumption: 195982,
                        fluidReactionsWater: 902.35,
                        fluidReactionsHeavyWater: 0.0,
                        fluidReactionsHighPressureWater: 540.13,
                        fluidReactionsHighPressureHeavyWater: 126.94,
                        deuteriumProduction: 1442.48,
                        tritiumProduction: 126.94,
                    },
                },
            ],
        })
    }, [])

    return state
}
